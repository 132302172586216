import dateFormat from "dateformat";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { ValueOpt } from "../form";
import {
  AcceptedDateTypes,
  DateDuration,
  MonthsValueOptions,
  NotApplicable,
  getMonthsValueOptions,
  getNumberValueOption,
  getYearValueOptions,
} from "../types";
import { isString, replaceAll } from "./string";

export const DATE_FORMATTER_STR = "mm/dd/yyyy";
export const DATE_FORMATTER_STR_WITH_TIME = "mm/dd/yyyy hh:MM TT";
export const DATE_FORMATTER_STR_WITH_TIME_AND_ZONE = "mm/dd/yyyy hh:MM TT Z";
export const TIME_FORMATTER_STR = "hh:MM TT";
export const HTML5_DATE_STRING = "yyyy-MM-dd";
export const HTML5_DATETIME_STRING = "yyyy-MM-dd'T'hh:MM";

export const DATE_FORMAT_STR = "MM/dd/yyyy";
export const TIME_FORMAT_STR = "hh:mm a";
export const DATE_FORMAT_STR_WITH_TIME = "MM/dd/yyyy hh:mm a";

export const isDateType = (value: any): boolean => value instanceof Date || value instanceof DateTime;

export const getRawDate = (value: any, format?: string): Date | undefined => {
  if (!!value) {
    if (value instanceof Date) {
      return value;
    } else if (value instanceof DateTime) {
      return (value as DateTime).toJSDate();
    } else if (isString(value)) {
      if (!!format) {
        return parseDate(value as string, format);
      } else {
        return new Date(value as string);
      }
    }
  }
  return undefined;
};

export const parseDate = (value: string, format = DATE_FORMAT_STR): Date =>
  DateTime.fromFormat(value as string, format).toJSDate();

export const getEnrichedDate = (
  toEnrich: AcceptedDateTypes | NotApplicable | undefined,
  existingValue: AcceptedDateTypes,
  format?: string,
): AcceptedDateTypes | NotApplicable | undefined => {
  if (!!toEnrich) {
    if (existingValue instanceof DateTime && toEnrich instanceof Date) {
      return DateTime.fromJSDate(toEnrich as Date);
    } else if (isString(existingValue) && toEnrich instanceof Date) {
      return DateTime.fromJSDate(toEnrich).toFormat(format ?? DATE_FORMATTER_STR);
    }
  }
  return toEnrich;
};

export const toLuxon = (date: AcceptedDateTypes, format?: string): DateTime => {
  if (date instanceof DateTime) {
    return date;
  } else if (date instanceof Date) {
    return DateTime.fromJSDate(date);
  } else {
    if (!!format) {
      return DateTime.fromFormat(date, format);
    } else {
      return DateTime.fromJSDate(new Date(date));
    }
  }
};

export const formatDate = (value?: AcceptedDateTypes | string, format = DATE_FORMATTER_STR, utc?: boolean): string =>
  !!value
    ? isString(value)
      ? dateFormat(getRawDate(value, format), format, utc ? utc : false, false)
      : dateFormat(getRawDate(value, format), format, utc ? utc : false, false)
    : "";

export const hasPast = (date: AcceptedDateTypes): boolean => isAfter(date, new Date());

export const isAfter = (date: AcceptedDateTypes, test: AcceptedDateTypes, format?: string): boolean => {
  const test1 = getRawDate(date, format);
  const test2 = getRawDate(test, format);
  if (!!test1 && !!test2) {
    return test1.getTime() >= test2.getTime();
  } else if (!!test1) {
    return true;
  } else {
    return false;
  }
};

export const isBefore = (date: AcceptedDateTypes, test: AcceptedDateTypes, format?: string): boolean => {
  const test1 = getRawDate(date, format);
  const test2 = getRawDate(test, format);
  if (!!test1 && !!test2) {
    return test1.getTime() <= test2.getTime();
  } else if (!!test2) {
    return true;
  } else {
    return false;
  }
};

export const validateDate = (date?: AcceptedDateTypes | number, format?: string) => {
  if (!date) {
    return false;
  }
  const convertedDate: Date | undefined = getRawDate(date, format);
  return !!convertedDate ? !isNaN(convertedDate.getTime()) : false;
};

export const formatDateWithSlashes = (date?: string): string => {
  return !date ? "" : replaceAll(date, "-", "/");
};

export const addDays = (date: AcceptedDateTypes, days: number, format?: string): AcceptedDateTypes => {
  if (!!date) {
    const rawDate: Date = getRawDate(date, format)!;
    rawDate.setDate(rawDate.getDate() + days);
    return getEnrichedDate(rawDate, date, format) as AcceptedDateTypes;
  } else {
    return date;
  }
};

export const addWeeks = (date: AcceptedDateTypes, weeks: number, format?: string): AcceptedDateTypes => {
  if (!!date) {
    const rawDate: Date = getRawDate(date, format)!;
    const dayOfWeek: number = rawDate.getDay();
    const newDate: Date = new Date(rawDate.getTime() + weeks * 7 * 24 * 60 * 60 * 1000);
    if (newDate.getDay() !== dayOfWeek) {
      newDate.setDate(newDate.getDate() - dayOfWeek + newDate.getDay());
    }
    return getEnrichedDate(newDate, date, format) as AcceptedDateTypes;
  } else {
    return date;
  }
};

export const addMonths = (date: AcceptedDateTypes, months: number, format?: string): AcceptedDateTypes => {
  if (!!date) {
    const rawDate: Date = getRawDate(date, format)!;
    let month: number = rawDate.getMonth();
    let year: number = rawDate.getFullYear();
    month += months;
    if (month > 11) {
      month -= 12;
      year += 1;
    }
    const newDate = new Date(year, month, rawDate.getDate());
    return getEnrichedDate(newDate, date, format) as AcceptedDateTypes;
  } else {
    return date;
  }
};

export const addYears = (date: AcceptedDateTypes, years: number, format?: string): AcceptedDateTypes => {
  if (!!date) {
    const rawDate: Date = getRawDate(date, format)!;
    let year: number = rawDate.getFullYear();
    year += years;
    const newDate: Date = new Date(year, rawDate.getMonth(), rawDate.getDate());
    return getEnrichedDate(newDate, date, format) as AcceptedDateTypes;
  } else {
    return date;
  }
};

const startingYear = 1980;

export const calculateMaxYear = (
  shouldFilter: boolean,
  minDate?: AcceptedDateTypes,
  maxDate?: AcceptedDateTypes,
  format?: string,
): ValueOpt<number>[] => {
  if (shouldFilter) {
    const finalMinDate: Date | undefined = getRawDate(minDate, format);
    const finalMaxDate: Date | undefined = getRawDate(maxDate, format);
    if (!!finalMaxDate && !!finalMinDate) {
      const minYear: number = finalMinDate.getFullYear();
      const maxYear: number = finalMaxDate.getFullYear();
      return getYearValueOptions(minYear, maxYear);
    } else if (!!finalMaxDate) {
      const maxYear: number = finalMaxDate.getFullYear();
      return getYearValueOptions(startingYear, maxYear);
    } else if (!!finalMinDate) {
      const minYear: number = finalMinDate.getFullYear();
      return getYearValueOptions(minYear, new Date().getFullYear() + 10);
    }
  }
  return getYearValueOptions(startingYear, new Date().getFullYear() + 10);
};

export const calculateMaxMonth = (
  shouldFilter: boolean,
  monthsValueOptions: MonthsValueOptions,
  yearValue?: ValueOpt<number>,
  minDate?: AcceptedDateTypes,
  maxDate?: AcceptedDateTypes,
  format?: string,
): ValueOpt<string>[] => {
  if (shouldFilter) {
    const finalMinDate: Date | undefined = getRawDate(minDate, format);
    const finalMaxDate: Date | undefined = getRawDate(maxDate, format);
    if (!!yearValue?.value) {
      if (!!finalMaxDate && !!finalMinDate) {
        const minYear: number = finalMinDate.getFullYear();
        const maxYear: number = finalMaxDate.getFullYear();

        if (yearValue.value === maxYear) {
          return getMonthsValueOptions(monthsValueOptions.slice(0, finalMaxDate.getMonth() + 1));
        } else if (yearValue.value === minYear) {
          return getMonthsValueOptions(monthsValueOptions.slice(finalMinDate.getMonth()));
        }
      } else if (!!finalMaxDate) {
        const maxYear: number = finalMaxDate.getFullYear();
        if (yearValue.value === maxYear) {
          return getMonthsValueOptions(monthsValueOptions.slice(0, finalMaxDate.getMonth() + 1));
        }
      } else if (!!finalMinDate) {
        const minYear: number = finalMinDate.getFullYear();
        if (yearValue.value === minYear) {
          return getMonthsValueOptions(monthsValueOptions.slice(finalMinDate.getMonth()));
        }
      }
    }
  }
  return getMonthsValueOptions(monthsValueOptions);
};

const maxDaysOfMonth: number[] = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
export const calculateMaxDay = (
  shouldFilter: boolean,
  monthsValueOptions: MonthsValueOptions,
  yearValue?: ValueOpt<number>,
  monthValue?: ValueOpt<string>,
  minDate?: AcceptedDateTypes,
  maxDate?: AcceptedDateTypes,
  format?: string,
): ValueOpt<number>[] => {
  let minDay = 1;
  let maxDay = 31;
  if (shouldFilter) {
    const finalMinDate: Date | undefined = getRawDate(minDate, format);
    const finalMaxDate: Date | undefined = getRawDate(maxDate, format);
    if (!!yearValue && !!monthValue) {
      const monthIndex: number = monthsValueOptions.findIndex((opt: string) => opt === monthValue?.value);
      if (monthIndex > -1) {
        maxDay = maxDaysOfMonth[monthIndex] || 31;
        if (!!finalMaxDate && !!finalMinDate) {
          const minYear: number = finalMinDate.getFullYear();
          const maxYear: number = finalMaxDate.getFullYear();
          const minMonth: number = finalMinDate.getMonth();
          const maxMonth: number = finalMaxDate.getMonth();
          if (yearValue.value === maxYear) {
            if (maxMonth === monthIndex) {
              maxDay = finalMaxDate.getDate();
            }
          } else if (yearValue.value === minYear) {
            if (minMonth === monthIndex) {
              minDay = finalMinDate.getDate();
            }
          }
        } else if (!!finalMaxDate) {
          const maxYear: number = finalMaxDate.getFullYear();
          const maxMonth: number = finalMaxDate.getMonth();
          if (yearValue.value === maxYear) {
            if (maxMonth === monthIndex) {
              maxDay = finalMaxDate.getDate();
            }
          }
        } else if (!!finalMinDate) {
          const minYear: number = finalMinDate.getFullYear();
          const minMonth: number = finalMinDate.getMonth();
          if (yearValue.value === minYear) {
            if (minMonth === monthIndex) {
              minDay = finalMinDate.getDate();
            }
          }
        }
      }

      //edge case for leap year
      if (monthIndex === 1 && yearValue?.value % 4 === 0 && maxDay === 28) {
        maxDay = 29;
      }
    }
  }
  return getNumberValueOption(minDay, maxDay);
};

export const useCountdown = (value: number): string => {
  const [currentTime, setCurrentTime] = useState(value);

  const millisToMinutesAndSeconds = (millis: number): string => {
    const minutes: number = Math.floor(millis / 60000);
    const seconds: number = parseInt(((millis % 60000) / 1000).toFixed(0));
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  };

  useEffect(() => {
    const intv: NodeJS.Timeout = setTimeout(() => {
      setCurrentTime(currentTime - 1000);
    }, 1000);

    return () => {
      if (!!intv) {
        clearInterval(intv);
      }
    };
  }, [currentTime]);

  return millisToMinutesAndSeconds(currentTime);
};

export const getDaysInMonth = (year: number, month: number): number => {
  return new Date(year, month, 0).getDate();
};

export const getTimeBetween = (start: AcceptedDateTypes, end: AcceptedDateTypes, format?: string): number => {
  const startDate: Date | undefined = getRawDate(start, format);
  const endDate: Date | undefined = getRawDate(end, format);
  if (!!startDate && !!endDate) {
    return startDate.getTime() - endDate.getTime();
  } else {
    return 0;
  }
};

export const getDaysBetween = (start: AcceptedDateTypes, end: AcceptedDateTypes, format?: string): number => {
  const difference = getTimeBetween(start, end, format);
  return Math.ceil(difference / (1000 * 3600 * 24));
};

export const getMonthsBetween = (start: AcceptedDateTypes, end: AcceptedDateTypes, format?: string): number => {
  const startDate: Date | undefined = getRawDate(start, format);
  const endDate: Date | undefined = getRawDate(end, format);
  if (!!startDate && !!endDate) {
    return endDate.getMonth() - startDate.getMonth() + 12 * (endDate.getFullYear() - startDate.getFullYear());
  } else {
    return 0;
  }
};

export const getWeeksBetween = (start: AcceptedDateTypes, end: AcceptedDateTypes, format?: string): number => {
  return parseFloat((getDaysBetween(start, end, format) / 7).toFixed(2));
};

export const getYearsBetween = (start: AcceptedDateTypes, end: AcceptedDateTypes, format?: string): number => {
  const startDate: Date | undefined = getRawDate(start, format);
  const endDate: Date | undefined = getRawDate(end, format);
  if (!!startDate && !!endDate) {
    return endDate.getFullYear() - startDate.getFullYear();
  } else {
    return 0;
  }
};

export const getDifference = (start: AcceptedDateTypes, end: AcceptedDateTypes, format?: string): DateDuration => {
  const startDate: DateTime = toLuxon(start, format);
  const endDate: DateTime = toLuxon(end, format);
  return endDate.diff(startDate, ["days", "months", "weeks", "years"]);
};

export const invalidStartDate = (
  duration: DateDuration,
  start: AcceptedDateTypes,
  end: AcceptedDateTypes,
  format?: string,
): boolean => {
  const lastPossibleDate: DateTime = toLuxon(start, format).plus(duration);
  return toLuxon(end, format).startOf("day") > lastPossibleDate.startOf("day");
};

export const invalidEndDate = (
  duration: DateDuration,
  start: AcceptedDateTypes,
  end: AcceptedDateTypes,
  format?: string,
): boolean => {
  const lastPossibleDate: DateTime = toLuxon(start, format).plus(duration);
  return toLuxon(end, format).startOf("day") > lastPossibleDate.startOf("day");
};

export const calculateMaxStartDate = (
  duration: DateDuration,
  start: AcceptedDateTypes,
  end: AcceptedDateTypes,
  format?: string,
): AcceptedDateTypes => {
  if (invalidStartDate(duration, start, end, format)) {
    return getEnrichedDate(toLuxon(end, format).minus(duration), start, format) as AcceptedDateTypes;
  } else {
    return start;
  }
};

export const calculateMaxEndDate = (
  duration: DateDuration,
  start: AcceptedDateTypes,
  end: AcceptedDateTypes,
  format?: string,
): AcceptedDateTypes => {
  if (invalidEndDate(duration, start, end, format)) {
    return getEnrichedDate(toLuxon(start, format).plus(duration), start, format) as AcceptedDateTypes;
  } else if (isAfter(start, end)) {
    return getEnrichedDate(toLuxon(start, format).plus(duration), start, format) as AcceptedDateTypes;
  } else {
    return end;
  }
};
