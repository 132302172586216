import React, { createContext, useCallback, useContext, useEffect, useReducer } from "react";
import { AlertConstants, AlertType, AlertTypes } from "../../types";
import { hasPast, randomString } from "../../utils";

interface AlertContextState {
  alerts: AlertType[];
  addAlert: (alert: AlertType) => void;
  removeAlert: (id: number | string) => void;
}

interface AlertReducerState {
  alerts: AlertType[];
}

type AlertAction =
  | { type: "addAlert"; payload: AlertType }
  | { type: "removeAlert"; payload: string | number }
  | { type: "checkForAlertsToRemove" };

const AlertContext = createContext<AlertContextState | undefined>(undefined);

const getAlertId = () => `_${randomString()}`;

const getTime = (type: AlertTypes) => {
  const typeStr = !!type ? type.toLowerCase() : "";
  switch (typeStr) {
    case AlertConstants.TYPES.IGNORE:
      return 0;
    case AlertConstants.TYPES.SUCCESS:
      return 4000;
    case AlertConstants.TYPES.INFO:
      return 7000;
    case AlertConstants.TYPES.WARNING:
      return 10000;
    case AlertConstants.TYPES.DANGER:
    default:
      //a day in advance
      return 1000 * 60 * 60 * 24;
  }
};

const reducer = (state: AlertReducerState, action: AlertAction) => {
  switch (action.type) {
    case "addAlert":
      const alert = action.payload;
      if (alert.type?.toLowerCase() === AlertConstants.TYPES.IGNORE.toLowerCase()) {
        return state;
      }
      if (!alert.id) {
        alert.id = getAlertId();
      }
      if (alert.selfClosing === undefined) {
        alert.selfClosing = true;
      }
      if (alert.selfClosing) {
        const closeTime: Date = new Date();
        closeTime.setTime(closeTime.getTime() + getTime(alert.type));
        alert.closeTime = closeTime;
      }
      return { alerts: [...state.alerts, alert] };
    case "removeAlert":
      const newAlerts: AlertType[] = [...state.alerts];
      const index = state.alerts.findIndex((alert) => alert.id === action.payload);
      newAlerts.splice(index, 1);
      return { alerts: newAlerts };
    case "checkForAlertsToRemove":
      const finalAlerts = state.alerts.filter((alert) => {
        if (!!alert.closeTime) {
          return hasPast(alert.closeTime);
        } else {
          return false;
        }
      });
      if (finalAlerts.length !== state.alerts.length) {
        return { alerts: finalAlerts };
      }
      return state;
    default:
      return state;
  }
};

const AlertProvider = ({ children }: { children: React.ReactNode }) => {
  // hooks
  const [state, dispatch] = useReducer(reducer, { alerts: [] });

  // functions
  const addAlert = useCallback(
    (alert: AlertType) => {
      dispatch({ type: "addAlert", payload: alert });
    },
    [dispatch],
  );

  const removeAlert = useCallback(
    (id: number | string) => {
      dispatch({ type: "removeAlert", payload: id });
    },
    [dispatch],
  );

  const checkForAlertsToRemove = useCallback(() => {
    dispatch({ type: "checkForAlertsToRemove" });
  }, [dispatch]);

  // effects
  useEffect(() => {
    const intv = setInterval(() => {
      checkForAlertsToRemove();
    }, 1000);

    return () => {
      if (!!intv) {
        clearInterval(intv);
      }
    };
  }, [checkForAlertsToRemove]);

  return (
    <AlertContext.Provider value={{ alerts: state.alerts, addAlert, removeAlert }}>{children}</AlertContext.Provider>
  );
};

const useAlerts = () => {
  const alertContext = useContext(AlertContext);
  if (alertContext === undefined) {
    throw new Error(`useAlert must be used within a AlertProvider`);
  }
  return alertContext;
};

export { AlertContext, AlertProvider, useAlerts };
